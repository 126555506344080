export default {
  items: [
    {
      title: 'System Code',
      url: '/sysenv/config/code',
      iconClass: 'jcon_code'
    },
    {
      title: 'Navigation',
      url: '/sysenv/config/nav',
      iconClass: 'jcon_navi'
    },
    {
      title: 'Account Management',
      url: '/sysenv/config/account',
      iconClass: 'jcon_account'
    },
    {
      title: 'Access History',
      url: '/sysenv/config/acchistory/overview',
      iconClass: 'jcon_calendar_01'
    },
    {
      title: 'Modifiability Controls',
      url: '/sysenv/config/modcon',
      iconClass: 'jcon_modify'
    },
    {
      title: 'Page Components',
      url: '/sysenv/config/page/dashboard',
      iconClass: 'jcon_page'
    },
    {
      title: 'Chart Library',
      url: '/sysenv/config/chartlib',
      iconClass: 'jcon_chart'
    },
    {
      title: 'SVG Component Library',
      url: '/sysenv/config/complibsvg',
      iconClass: 'jcon_library'
    },
    // {
    //   title: 'DOM Component Library',
    //   url: '/sysenv/config/complibsvg',
    //   iconClass: 'jcon_library'
    // },
    {
      title: 'Common Library',
      url: '/sysenv/config/lib',
      iconClass: 'jcon_library'
    },
    {
      title: 'Workorder Status',
      url: '/sysenv/config/workorder',
      iconClass: 'jcon_order'
    },
    {
      title: 'Database Support',
      url: '/sysenv/config/database',
      iconClass: 'jcon_database'
    },
    {
      title: 'Database Update',
      url: '/sysenv/config/dbupdate',
      iconClass: 'jcon_update'
    },
    {
      title: 'Update Notice',
      url: '/sysenv/config/notice',
      iconClass: 'jcon_comments'
    },
    {
      title: '<<< File Manager >>>',
      url: '/sysenv/config/filebrowser',
      iconClass: 'jcon_FloatingTable'
    },
  ]
}
